<template>
  <div v-if="permissions.u">
    <button-top
      v-if="permissions.u"
      :must-icon="false"
      tooltip="Guardar especie"
      name="Guardar"
      :date="form.created_at"
      @action="update"
    ></button-top>

    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Nombre</label>
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="last_name" class="text-success">Apellido</label>
              <validation-provider
                #default="{ errors }"
                name="Apellido"
                rules="required"
              >
                <b-form-input
                  id="last_name"
                  v-model="form.last_name"
                  :state="errors.length > 0 ? false : null"
                  name="last_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--  -->
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="type_document_id" class="text-success"
                >Tipo Documento</label
              >
              <validation-provider
                #default="{ errors }"
                name="Tipo documento"
                rules="required"
              >
                <b-form-select
                  id="type_document_id"
                  class="fl"
                  v-model="form.type_document_id"
                  value-field="id"
                  text-field="name"
                  :options="list.types_documents"
                  :state="errors.length > 0 ? false : null"
                  name="type_document_id"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="document_number" class="text-success"
                >Numero documento</label
              >
              <validation-provider
                #default="{ errors }"
                name="Numero documento"
                rules="required"
              >
                <b-form-input
                  id="document_number"
                  v-model="form.document_number"
                  :state="errors.length > 0 ? false : null"
                  name="document_number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="email" class="text-success">Correo</label>
              <validation-provider
                #default="{ errors }"
                name="Correo"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="mobile" class="text-success">Movil</label>
              <validation-provider
                #default="{ errors }"
                name="Movil"
                rules="required"
              >
                <b-form-input
                  id="mobile"
                  v-model="form.mobile"
                  :state="errors.length > 0 ? false : null"
                  name="mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="password" class="text-success">Contraseña</label>
              <validation-provider
                #default="{ errors }"
                name="Contraseña"
                rules="min:8"
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  :state="errors.length > 0 ? false : null"
                  name="password"
                  type="password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="position" class="text-success">Cargo</label>
              <validation-provider
                #default="{ errors }"
                name="Cargo"
                rules="required"
              >
                <b-form-input
                  id="position"
                  v-model="form.position"
                  :state="errors.length > 0 ? false : null"
                  name="position"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="profile_id" class="text-success">Rol</label>
              <validation-provider
                #default="{ errors }"
                name="Rol"
                rules="required"
              >
                <b-form-select
                  id="profile_id"
                  class="fl"
                  v-model="form.profile_id"
                  value-field="id"
                  text-field="name"
                  :options="list.profiles"
                  :state="errors.length > 0 ? false : null"
                  name="profile_id"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="show_third">
            <b-form-group class="w-100">
              <label for="third_id" class="text-success">Tercero</label>
              <validation-provider
                #default="{ errors }"
                name="Tercero"
                rules="required"
              >
                <b-form-select
                  id="third_id"
                  class="fl"
                  v-model="form.third_id"
                  value-field="id"
                  text-field="name"
                  :options="list.thirds"
                  :state="errors.length > 0 ? false : null"
                  name="third_id"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="country_id" class="text-success">Pais</label>
              <validation-provider
                #default="{ errors }"
                name="Pais"
                rules="required"
              >
                <b-form-select
                  id="country_id"
                  class="fl"
                  v-model="form.country_id"
                  @change="getDepartments()"
                  value-field="id"
                  text-field="name"
                  :options="list.countries"
                  :state="errors.length > 0 ? false : null"
                  name="country_id"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="department_id" class="text-success"
                >Departamento</label
              >
              <validation-provider
                #default="{ errors }"
                name="Departamento"
                rules="required"
              >
                <b-form-select
                  id="department_id"
                  class="fl"
                  v-model="form.department_id"
                  @change="getCities()"
                  value-field="id"
                  text-field="name"
                  :options="list.departments"
                  :state="errors.length > 0 ? false : null"
                  name="department_id"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="city_id" class="text-success">Municipio</label>
              <validation-provider
                #default="{ errors }"
                name="Municipio"
                rules="required"
              >
                <b-form-select
                  id="city_id"
                  class="fl"
                  v-model="form.city_id"
                  value-field="id"
                  text-field="name"
                  :options="list.cities"
                  :state="errors.length > 0 ? false : null"
                  name="city_id"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="state" class="text-success">Estado</label>
              <validation-provider #default="{ errors }" name="Estado">
                <b-form-checkbox
                  v-model="form.state"
                  id="state"
                  name="state"
                  switch
                  :value="1"
                  :unchecked-value="0"
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: "",
        name: "",
        last_name: "",
        type_document_id: "",
        document_number: "",
        email: "",
        mobile: "",
        password: "",
        position: "",
        profile_id: "",
        third_id: "",
        country_id: "",
        department_id: "",
        city_id: "",
        state: 1,
      },

      list: {
        types_documents: [],
        countries: [],
        departments: [],
        cities: [],
        profiles: [],
        thirds: [],
      },
    };
  },
  computed: {
    show_third() {
      const i = this.list.profiles.findIndex(
        (p) => p.id === this.form.profile_id
      );
      if (i >= 0 && this.list.profiles[i].name.toLowerCase() === "operario") {
        return true;
      }
      this.form.third_id = null;
      return false;
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.form.id = this.$route.params.id;
      await this.getPermission("u");
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.form = (
          await this.$http.get(`administration/user/show/${this.form.id}`)
        ).data.data;
        this.setHeaderSubTitle(
          `${this.form.document_number} / ${this.form.name}`
        );
        this.list.types_documents = (
          await this.$http.get("configuration/type-document/index")
        ).data.data;
        this.list.profiles = (
          await this.$http.get("administration/profile/index")
        ).data.data;
        this.list.thirds = (
          await this.$http.get("administration/integration/third/index", {
            params: {
              no_paginate: true,
            },
          })
        ).data.data;
        this.list.countries = (
          await this.$http.get("configuration/country/index")
        ).data.data;
        this.list.departments = (
          await this.$http.get(
            `configuration/department/show/${this.form.country_id}`
          )
        ).data.data;
        this.list.cities = (
          await this.$http.get(
            `configuration/city/show/${this.form.department_id}`
          )
        ).data.data;
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: "usuarios" });
        }
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getCountries() {
      try {
        this.loading();
        this.list.countries = (
          await this.$http.get("configuration/country/index")
        ).data.data;
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getDepartments() {
      try {
        this.loading();
        this.list.departments = (
          await this.$http.get(
            `configuration/department/show/${this.form.country_id}`
          )
        ).data.data;
        this.form.department_id = "";
        this.form.city_id = "";
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getCities() {
      try {
        this.loading();
        this.list.cities = (
          await this.$http.get(
            `configuration/city/show/${this.form.department_id}`
          )
        ).data.data;
        this.form.city_id = "";
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    update() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading();
          this.$http
            .patch(`administration/user/update/${this.form.id}`, this.form)
            .then((res) => {
              this.notify(
                "Proceso satisfactorio.",
                res.data.message,
                "primary"
              );
              this.$router.push(`/usuarios/show/${this.form.id}`);
            })
            .catch((err) => {
              this.notify("Error", err.response.data.message, "danger");
            })
            .finally(() => {
              this.not_loading();
            });
        }
      });
    },
  },
};
</script>